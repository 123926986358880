import React from 'react';
import PropTypes from 'prop-types';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Logo from 'reusecore/src/elements/UI/Logo';
import Container from '../../../components/UI/Container';
import FooterWrapper, { List, ListItem } from './footer.style';
import Link from 'next/link';

import LogoImage from '../../../assets/image/saas/logo.png';

import { Footer_Data } from '../../../data/Saas';

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
  textStyle,
  copyrightStyle,
  miniText,
  subFooter,
}) => {
  return (
    <FooterWrapper>
      <Container className="footer_container">
        <Box className="row" {...row}>
          <Box {...colOne}>
            <Logo
              href="#"
              logoSrc={LogoImage}
              title="Bpifrance"
              logoStyle={logoStyle}
            />
            <Text content="2019 © Copyright Bpifrance. " {...copyrightStyle} />
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo}>
            {Footer_Data.map((widget, index) => (
              <Box className="col" {...col} key={`footer-widget-${index}`}>
                <Heading content={widget.title} {...titleStyle} />
                <List>
                  {widget.menuItems.map((item, index) => (
                    <ListItem key={`footer-list-item-${index}`}>
                      {item.staticLink ? (
                        <Link href={item.url}>
                          <a>{item.text}</a>
                        </Link>
                        )
                        :(
                      <AnchorLink href={item.url}>
                      {item.text}
                      </AnchorLink>)
                      }
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          {/* End of footer List column */}



          <Box>
          <Text content="L’accès au service est soumis à l’acceptation des Conditions Générales d’Utilisation de Bpifrance et de son partenaire agrégateur Budget Insight." {...miniText}></Text>
          <Text content="Budget Insight est un établissement de paiement agréé pour la fourniture du service d'initiation de paiement et du service d’information sur les comptes." {...miniText}></Text>
          <Text content="Vos données personnelles sont traitées conformément à la loi informatique et liberté et aux règlement générale sur la protection des données. Seules les données personnelles nécessaires à l’exploitation des services seront traitées. " {...miniText}></Text>
          <Text content=" " {...miniText}></Text>
        </Box>
        </Box>
        
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Footer col one style
  colOne: {
    width: [1, '35%', '35%', '23%'],
    mt: [0, '13px'],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0],
    },
  // Footer col two style
  colTwo: {
    width: ['100%', '65%', '65%', '77%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '25%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
  // widget title default style
  titleStyle: {
    color: '#5E514D',
    fontSize: '16px',
    fontWeight: '700',
    mb: '30px',
  },
  // widget title default style
  copyrightStyle: {
    color: '#5E514D',
    fontSize: '10px',
    fontWeight: '700',
    mb: '30px',
  },
  // Default logo size
  logoStyle: {
    width: '135px',
    mb: '15px',
  },
  // widget text default style
  textStyle: {
    color: '#5E514D',
    fontSize: '16px',
    mb: '10px',
  },
  miniText: {
    color: '#5E514D',
    fontSize: '11px',
    fontWeight: '400',
    mb: '10px',
  },
};

export default Footer;
